import React from 'react'
import { Grid, Card, CardActionArea, CardContent, Typography, CardMedia } from '@mui/material';
function Nltspc() {
  return (
    <Grid className='m-5'>
      <h1>Nltspc page</h1>
    </Grid>
  )
}

export default Nltspc;
