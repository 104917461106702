import React from 'react';
import Grid from '@mui/material/Grid';
import './ReciprocatingAirCompressor.css';
import Typography from '@mui/material/Typography';

const ReciprocatingAirCompressor = () => {
  return (
    <Grid className="kj" style={{margin:"20px"}}>
      <h1>Reciprocating Air Compressor</h1>
      <h3>Non-Lubricated Two Stage 8 Bar Air Compressor</h3>
      <Typography>Air Compressor which is Non-Lubricated, Two Stage, 8 Bar, Double acting, Reciprocating, Horizontal Balanced opposed, heavy duty, water cooled, with force-feed lubrication to all bearings, Compressor sheave with fly wheel effect.</Typography>
      
    </Grid>
  );
};

export default ReciprocatingAirCompressor;   