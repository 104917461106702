import React from 'react'

const Ir747epp = () => {
  return (
    <div>
      <h1>Ir747epp.js</h1>
    </div>
  )
}

export default Ir747epp
