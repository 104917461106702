import React from 'react'

const Ir1277inop = () => {
  return (
    <div>
      <h1>Ir1277inop.js</h1>
    </div>
  )
}

export default Ir1277inop
