import React from 'react'

const Ir169b7in2p = () => {
  return (
    <div>
      <h1>Ir169b7in2p.js</h1>
    </div>
  )
}

export default Ir169b7in2p
