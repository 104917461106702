import React from 'react'

const Ir1277pp = () => {
  return (
    <div>
      <h1>Ir1277pp</h1>
    </div>
  )
}

export default Ir1277pp
