import React from 'react'

const Ir547pn = () => {
  return (
    <div>
      <h1>Ir547pn.js</h1>
    </div>
  )
}

export default Ir547pn
