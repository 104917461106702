import React from 'react'

const Ir16107inp = () => {
  return (
    <div>
      <h1>Ir16107inp</h1>
    </div>
  )
}

export default Ir16107inp
