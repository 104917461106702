import React from 'react'

const Ir1067pnp = () => {
  return (
    <div>
      <h1>Ir1067pnp.js</h1>
    </div>
  )
}

export default Ir1067pnp
