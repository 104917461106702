import React from 'react'

const Ir1277pnp = () => {
  return (
    <div>
      <h1>Ir1277pnp</h1>
    </div>
  )
}

export default Ir1277pnp;
